import Vue from 'vue';
import VueRouter from 'vue-router';

// 解决 `element ui` 导航栏重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 安装 VueRouter 插件
Vue.use(VueRouter);

/* Layout */
import Layout from '@/layout/index';
export const constantRoutes = [
	{
		path: '/login',
		component: () => import('@/views/login'),
		hidden: true,
		meta: {
			title: '登录',
		},
	},

	{
		path: '/404',
		component: () => import('@/views/error/404'),
		hidden: true,
		meta: {
			title: '404',
		},
	},
	{
		path: '/',
		component: Layout,
		redirect: '/home',
	},
	{
		path: '/chatRoom',
		name: 'chatRoom',
		props: (route) => {
			return route.query;
		},
		component: () => import('@/views/homePage/chatRoom'),
	},
	{
		path: '/charRoom',
		name: 'charRoom',
		props: (route) => {
			return route.query;
		},
		component: () => import('@/views/homePage/charRoom'),
	},
	{
		path: '/home',
		component: Layout,
		redirect: '/home/dashboard/overview',
		name: 'home',
		meta: {
			title: '数据驾驶舱',
			roles: ['view'],
		},
		children: [
			{
				path: 'dashboard',
				name: 'index',
				component: () => import('@/views/homePage/index'),
				meta: {
					title: '巡诊驻派数据驾驶舱',
					roles: ['view'],
				},
				children: [
					{
						path: 'overview',
						component: () => import('@/views/homePage/overview/index'),
						meta: {
							title: '总览',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/medic',
						component: () => import('@/views/homePage/medic/index'),
						meta: {
							title: '医疗服务',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/public',
						component: () => import('@/views/homePage/public/index'),
						meta: {
							title: '公共卫生',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/tcm',
						component: () => import('@/views/homePage/tcm/index'),
						meta: {
							title: '中医药建设',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/train',
						component: () => import('@/views/homePage/train/index'),
						meta: {
							title: '培训服务',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/kpi',
						component: () => import('@/views/homePage/kpi/index'),
						meta: {
							title: '绩效体系',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/visitAndDispatch',
						component: () => import('@/views/homePage/visitAndDispatch/index'),
						meta: {
							title: '巡诊驻派',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/teamwork',
						component: () => import('@/views/homePage/teamwork/index'),
						meta: {
							title: '合作企业',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/treatment',
						component: () => import('@/views/homePage/treatment/index'),
						meta: {
							title: '慢病防治',
							roles: ['view'],
						},
					},
					{
						path: '/home/dashboard/doctor',
						component: () => import('@/views/homePage/doctor/index'),
						meta: {
							title: '家庭医生',
							roles: ['view'],
						},
					},
				],
			},
		],
	},
	{
		path: '/account',
		component: Layout,
		redirect: '/account/personalInfo',
		name: 'accountSet',
		meta: {
			title: '账号设置',
			roles: ['view', 'crud'],
		},
		children: [
			{
				path: '/account/personalInfo',
				name: 'accountPersonalInfo',
				component: () => import('@/views/account/personalInfo'),
				meta: {
					title: '个人信息',
					roles: ['view', 'crud'],
				},
			},
		],
	},
	{
		path: '/institutionManager',
		component: Layout,
		redirect: '/institutionManager/countyMedicalManager',
		name: 'institutionManager',
		meta: {
			title: '机构管理',
			roles: ['crud'],
		},
		children: [
			{
				path: '/institutionManager/countyMedicalManager',
				name: 'countyMedicalManager',
				component: () => import('@/views/institutionManager/countyMedicalManager'),
				meta: {
					title: '医疗机构管理',
					roles: ['crud'],
				},
			},
			{
				path: '/institutionManager/medicalAndHealth',
				name: 'medicalAndHealth',
				component: () => import('@/views/institutionManager/medicalAndHealth'),
				meta: {
					title: '医疗卫生机构管理',
					roles: ['crud'],
				},
			},
			// {
			// 	path: '/institutionManager/villageMedicalManager',
			// 	name: 'villageMedicalManager',
			// 	component: () => import('@/views/institutionManager/villageMedicalManager'),
			// 	meta: {
			// 		title: '村卫生院信息管理',
			// 		roles: ['crud'],
			// 	},
			// },
			// {
			// 	path: '/institutionManager/communityManager',
			// 	name: 'communityManager',
			// 	component: () => import('@/views/institutionManager/communityManager'),
			// 	meta: {
			// 		title: '社区管理',
			// 		roles: ['crud'],
			// 	},
			// },
			{
				path: '/institutionManager/departmentManager',
				name: 'departmentManager',
				component: () => import('@/views/institutionManager/departmentManager'),
				meta: {
					title: '科室管理',
					roles: ['crud'],
				},
			},
			// {
			// 	path: '/institutionManager/postManager',
			// 	name: 'postManager',
			// 	component: () => import('@/views/institutionManager/postManager'),
			// 	meta: {
			// 		title: '岗位管理',
			// 		roles: ['crud'],
			// 	},
			// },
			// {
			// 	path: '/institutionManager/titleManager',
			// 	name: 'titleManager',
			// 	component: () => import('@/views/institutionManager/titleManager'),
			// 	meta: {
			// 		title: '职称管理',
			// 		roles: ['crud'],
			// 	},
			// },
			{
				path: '/institutionManager/medicalStaff',
				name: 'medicalStaff',
				component: () => import('@/views/institutionManager/medicalStaff'),
				meta: {
					title: '医护人员管理',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/communityManager',
		component: Layout,
		redirect: '/communityManager/community',
		name: 'communityManager',
		meta: {
			title: '社区管理',
			roles: ['crud'],
		},
		children: [
			{
				path: '/communityManager/community',
				name: 'community',
				component: () => import('@/views/communityManager/community'),
				meta: {
					title: '社区信息管理',
					roles: ['crud'],
				},
			},
			{
				path: '/communityManager/familyInfo',
				name: 'peopleManager',
				component: () => import('@/views/communityManager/familyInfo'),
				meta: {
					title: '社区居民信息管理',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/userManager',
		component: Layout,
		redirect: '/userManager/familyInfo',
		name: 'userManager',
		meta: {
			title: '群众管理',
			roles: ['crud'],
		},
		children: [
			{
				path: '/userManager/familyInfo',
				name: 'userManagerFamilyInfo',
				component: () => import('@/views/userManager/familyInfo'),
				meta: {
					title: '家庭信息',
					roles: ['crud'],
				},
			},
			{
				path: '/userManager/showDetails',
				name: 'showDetailsFamily',
				component: () => import('@/views/userManager/familyInfo/showDetails'),
				meta: {
					title: '家庭信息详情',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/publicHealth',
		component: Layout,
		redirect: '/publicHealth/healthyText',
		name: 'publicHealth',
		meta: {
			title: '公共卫生',
			roles: ['crud'],
		},
		children: [
			{
				path: '/publicHealth/healthyText',
				name: 'healthyText',
				component: () => import('@/views/publicHealth/healthyText'),
				meta: {
					title: '健康档案',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/healthyText/showDetails',
				name: 'healthyTextShowDetails',
				component: () => import('@/views/publicHealth/healthyText/showDetails'),
				meta: {
					title: '健康档案详情',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/elderlyHealth',
				name: 'elderlyHealth',
				component: () => import('@/views/publicHealth/elderlyHealth'),
				meta: {
					title: '老年人健康',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/elderlyHealth/showDetails',
				name: 'elderlyHealthDetails',
				component: () => import('@/views/publicHealth/elderlyHealth/showDetails'),
				meta: {
					title: '老年人健康详情',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/infectiousDisease',
				name: 'infectiousDisease',
				component: () => import('@/views/publicHealth/infectiousDisease'),
				meta: {
					title: '传染病',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/infectiousDisease/details',
				name: 'infectiousDiseaseDetails',
				component: () => import('@/views/publicHealth/publicEmergencies/details'),
				meta: {
					title: '传染病详情',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/publicEmergencies',
				name: 'publicEmergencies',
				component: () => import('@/views/publicHealth/publicEmergencies'),
				meta: {
					title: '公共突发事件',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/publicEmergencies/details',
				name: 'publicEmergenciesDetails',
				component: () => import('@/views/publicHealth/publicEmergencies/details'),
				meta: {
					title: '公共突发事件详情',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/healthEducation',
				name: 'publicHealthHealthEducation',
				component: () => import('@/views/publicHealth/healthEducation'),
				meta: {
					title: '健康教育',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/inoculateAgainst',
				name: 'inoculateAgainst',
				component: () => import('@/views/publicHealth/inoculateAgainst'),
				meta: {
					title: '接种预防',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/inoculateAgainst/details',
				name: 'inoculateAgainstDetails',
				component: () => import('@/views/publicHealth/inoculateAgainst/details'),
				meta: {
					title: '接种预防详情',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/healthSupervision',
				name: 'healthSupervision',
				component: () => import('@/views/publicHealth/healthSupervision'),
				meta: {
					title: '卫生计生监管',
					roles: ['crud'],
				},
			},
			{
				path: '/publicHealth/healthSupervision/details',
				name: 'details',
				component: () => import('@/views/publicHealth/healthSupervision/showDetails'),
				meta: {
					title: '详情',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/basicMedical',
		component: Layout,
		redirect: '/basicMedical/receivingHistory',
		name: 'basicMedical',
		meta: {
			title: '基本医疗',
			roles: ['crud'],
		},
		children: [
			{
				path: '/basicMedical/receivingHistory',
				name: 'receivingHistory',
				component: () => import('@/views/basicMedical/receivingHistory'),
				meta: {
					title: '接转诊记录',
					roles: ['crud'],
				},
			},
			{
				path: '/basicMedical/inspectHistory',
				name: 'inspectHistory',
				component: () => import('@/views/basicMedical/inspectHistory'),
				meta: {
					title: '检查检验',
					roles: ['crud'],
				},
			},
			{
				path: '/basicMedical/inspectHistory/showDetails',
				name: 'detailsInspect',
				component: () => import('@/views/basicMedical/inspectHistory/showDetails'),
				meta: {
					title: '检验详情',
					roles: ['crud'],
				},
			},
			{
				path: '/basicMedical/TCM',
				name: 'TCM',
				component: () => import('@/views/basicMedical/TCM'),
				meta: {
					title: '中医医疗',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/patrolWorkArrangement',
		component: Layout,
		redirect: '/patrolWorkArrangement/carManagement',
		name: 'patrolWorkArrangement',
		meta: {
			title: '巡诊驻派管理',
			roles: ['crud'],
		},
		children: [
			{
				path: '/patrolWorkArrangement/carManagement',
				name: 'carManagement',
				component: () => import('@/views/patrolWorkArrangement/carManagement'),
				meta: {
					title: '车辆管理',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/deviceManagement',
				name: 'deviceManagement',
				component: () => import('@/views/patrolWorkArrangement/deviceManagement'),
				meta: {
					title: '设备管理',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/drugManagement',
				name: 'drugManagement',
				component: () => import('@/views/patrolWorkArrangement/drugManagement'),
				meta: {
					title: '药品管理',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/patrolTeam',
				name: 'patrolTeam',
				component: () => import('@/views/patrolWorkArrangement/patrolTeam'),
				meta: {
					title: '巡诊组管理',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/visitPlan',
				name: 'visitPlan',
				component: () => import('@/views/patrolWorkArrangement/visitPlan'),
				meta: {
					title: '巡诊计划',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/workMoudle',
				name: 'workMoudle',
				component: () => import('@/views/patrolWorkArrangement/workMoudle'),
				meta: {
					title: '巡诊工作模块管理',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/jobChange',
				name: 'jobChange',
				component: () => import('@/views/patrolWorkArrangement/jobChange'),
				meta: {
					title: '换岗申请',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/patrolRuleSet',
				name: 'patrolRuleSet',
				component: () => import('@/views/patrolWorkArrangement/visitPlan/ruleSet'),
				meta: {
					title: '巡诊规则设定',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/residencyTeam',
				name: 'residencyTeam',
				component: () => import('@/views/patrolWorkArrangement/residencyTeam'),
				meta: {
					title: '驻派组管理',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/residencyPlan',
				name: 'residencyPlan',
				component: () => import('@/views/patrolWorkArrangement/residencyPlan'),
				meta: {
					title: '驻派计划',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/residencyWorkMoudle',
				name: 'residencyWorkMoudle',
				component: () => import('@/views/patrolWorkArrangement/residencyWorkMoudle'),
				meta: {
					title: '驻派工作模块管理',
					roles: ['crud'],
				},
			},
			{
				path: '/patrolWorkArrangement/residencyRuleSet',
				name: 'residencyRuleSet',
				component: () => import('@/views/patrolWorkArrangement/residencyPlan/ruleSet'),
				meta: {
					title: '驻派规则设定',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/familyDoctorManager',
		component: Layout,
		redirect: '/familyDoctorManager/contractManager',
		name: 'familyDoctorManager',
		meta: {
			title: '家庭签约医生',
			roles: ['crud'],
		},
		children: [
			// {
			// 	path: '/familyDoctorManager/serviceInstructions',
			// 	name: 'serviceInstructions',
			// 	component: () => import('@/views/familyDoctorManager/serviceInstructions'),
			// 	meta: {
			// 		title: '服务须知',
			// 		roles: ['crud'],
			// 	},
			// },
			{
				path: '/familyDoctorManager/contractManager',
				name: 'contractManager',
				component: () => import('@/views/familyDoctorManager/contractManager'),
				meta: {
					title: '服务条款',
					roles: ['crud'],
				},
			},
			// {
			// 	path: '/familyDoctorManager/serviceContent',
			// 	name: 'serviceContent',
			// 	component: () => import('@/views/familyDoctorManager/serviceContent'),
			// 	meta: {
			// 		title: '服务内容及条款',
			// 		roles: ['crud'],
			// 	},
			// },
			{
				path: '/familyDoctorManager/healthService',
				name: 'healthService',
				component: () => import('@/views/familyDoctorManager/healthService'),
				meta: {
					title: '卫生服务团队管理',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/healthService/homeFamily',
				name: 'healthServiceHomeFamily',
				component: () => import('@/views/familyDoctorManager/healthService/homeFamily'),
				meta: {
					title: '卫生服务团队家庭指导',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/doctorService',
				name: 'doctorService',
				component: () => import('@/views/familyDoctorManager/doctorService'),
				meta: {
					title: '家庭签约医生服务请求',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/termManagement',
				name: 'termManagement',
				component: () => import('@/views/familyDoctorManager/termManagement'),
				meta: {
					title: '合同条款管理',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/healthEducation',
				name: 'healthEducation',
				component: () => import('@/views/familyDoctorManager/healthEducation'),
				meta: {
					title: '健康教育',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/signingManager',
				name: 'signingManager',
				component: () => import('@/views/familyDoctorManager/signingManager'),
				meta: {
					title: '签约管理',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/physicalExaminationManager',
				name: 'physicalExaminationManager',
				component: () => import('@/views/familyDoctorManager/physicalExaminationManager'),
				meta: {
					title: '体检管理',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/physicalExaminationManager/details',
				name: 'physicalExaminationManagerDetails',
				component: () => import('@/views/familyDoctorManager/physicalExaminationManager/details'),
				meta: {
					title: '体检管理详情',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/healthFollowUp',
				name: 'healthFollowUp',
				component: () => import('@/views/familyDoctorManager/healthFollowUp'),
				meta: {
					title: '健康随访',
					roles: ['crud'],
				},
			},
			{
				path: '/familyDoctorManager/healthCounseling',
				name: 'healthCounseling',
				component: () => import('@/views/familyDoctorManager/healthCounseling'),
				meta: {
					title: '健康咨询',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/healthActivity',
		component: Layout,
		redirect: '/healthActivity/physicalActivity',
		name: 'healthActivity',
		meta: {
			title: '健康活动',
			roles: ['crud'],
		},
		children: [
			{
				path: '/healthActivity/physicalActivity',
				name: 'physicalActivity',
				component: () => import('@/views/healthActivity/physicalActivity'),
				meta: {
					title: '健康活动',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/trainingOrganization',
		component: Layout,
		redirect: '/trainingOrganization/traningManagement',
		name: 'trainingOrganization',
		meta: {
			title: '培训组织服务',
			roles: ['crud'],
		},
		children: [
			{
				path: '/trainingOrganization/traningManagement',
				name: 'traningManagement',
				component: () => import('@/views/trainingOrganization/traningManagement'),
				meta: {
					title: '业务培训',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/staticTotalView',
		component: Layout,
		redirect: '/staticTotalView/userInfo',
		name: 'staticTotalView',
		meta: {
			title: '基础数据统计',
			roles: ['view'],
		},
		children: [
			{
				path: '/staticTotalView/userInfo',
				name: 'userInfo',
				component: () => import('@/views/staticTotalView/userInfo'),
				meta: {
					title: '用户信息统计',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/familyInfo',
				name: 'familyInfo',
				component: () => import('@/views/staticTotalView/familyInfo/index'),
				meta: {
					title: '家庭信息统计',
					roles: ['view'],
				},
			},

			{
				path: '/staticTotalView/institutionalStatistics',
				name: 'institutionalStatistics',
				component: () => import('@/views/staticTotalView/institutionalStatistics/index'),
				meta: {
					title: '机构统计',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/medicalStaffStatistics',
				name: 'medicalStaffStatistics',
				component: () => import('@/views/staticTotalView/medicalStaffStatistics'),
				meta: {
					title: '医护人员统计',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/resourceStatic',
				name: 'resourceStatic',
				component: () => import('@/views/staticTotalView/resourceStatic'),
				meta: {
					title: '资源统计',
					roles: ['view'],
				},
			},
			// {
			// 	path: '/staticTotalView/healthEqu',
			// 	name: 'healthEqu',
			// 	component: () => import('@/views/staticTotalView/healthEqu'),
			// 	meta: {
			// 		title: '康养设备统计',
			// 		roles: ['view'],
			// 	},
			// },
			{
				path: '/staticTotalView/patrolStatistics',
				name: 'patrolStatistics',
				component: () => import('@/views/staticTotalView/patrolStatistics'),
				meta: {
					title: '巡诊计划统计',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/residentStatistics',
				name: 'residentStatistics',
				component: () => import('@/views/staticTotalView/residentStatistics'),
				meta: {
					title: '驻派计划统计',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/childImmunity',
				name: 'childImmunity',
				component: () => import('@/views/staticTotalView/childImmunity'),
				meta: {
					title: '接种预防统计',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/publicEmergencies',
				name: 'publicEmergenciesTotal',
				component: () => import('@/views/staticTotalView/publicEmergencies'),
				meta: {
					title: '公共突发事件统计',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/publicEmergencies/details',
				name: 'publicEmergenciesDetailsTotal',
				component: () => import('@/views/staticTotalView/publicEmergencies/details'),
				meta: {
					title: '公共突发事件详情',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/familyDoctor',
				name: 'familyDoctor',
				component: () => import('@/views/staticTotalView/familyDoctor/index'),
				meta: {
					title: '家庭签约医生统计',
					roles: ['view'],
				},
			},
			{
				path: '/staticTotalView/familyInfo/showDetails',
				name: 'showDetails',
				component: () => import('@/views/staticTotalView/familyInfo/showDetails'),
				meta: {
					title: '家庭信息详情',
					roles: ['view'],
				},
			},
		],
	},
	{
		path: '/staticManager',
		component: Layout,
		redirect: '/staticManager/medicalStaffData',
		name: 'staticManager',
		meta: {
			title: '数据分析',
			roles: ['view'],
		},
		children: [
			{
				path: '/staticManager/medicalStaffData',
				name: 'medicalStaffData',
				component: () => import('@/views/staticManager/medicalStaffData/index'),
				meta: {
					title: '医护人员数据分析',
					roles: ['view'],
				},
			},
			{
				path: '/staticManager/importPersonData',
				name: 'importPersonData',
				component: () => import('@/views/staticManager/importPersonData/index'),
				meta: {
					title: '重点人群数据分析',
					roles: ['view'],
				},
			},

			{
				path: '/staticManager/infectiousDiseaseData',
				name: 'infectiousDiseaseData',
				component: () => import('@/views/staticManager/infectiousDiseaseData/index'),
				meta: {
					title: '传染病数据分析',
					roles: ['view'],
				},
			},
			{
				path: '/staticManager/healthEduData',
				name: 'healthEduData',
				component: () => import('@/views/staticManager/healthEduData/index'),
				meta: {
					title: '健康教育数据分析',
					roles: ['view'],
				},
			},
			{
				path: '/staticManager/receiveData',
				name: 'receiveData',
				component: () => import('@/views/staticManager/receiveData/index'),
				meta: {
					title: '接转诊数据分析',
					roles: ['view'],
				},
			},
			{
				path: '/staticManager/inspectData',
				name: 'inspectData',
				component: () => import('@/views/staticManager/inspectData/index'),
				meta: {
					title: '检查检验数据分析',
					roles: ['view'],
				},
			},
			{
				path: '/staticManager/deviceData',
				name: 'deviceData',
				component: () => import('@/views/staticManager/deviceData/index'),
				meta: {
					title: '设备数据分析',
					roles: ['view'],
				},
			},
		],
	},
	{
		path: '/performanceManager',
		component: Layout,
		redirect: '/performanceManager/index',
		children: [
			{
				path: '/performanceManager/index',
				name: 'performanceManager',
				component: () => import('@/views/performanceManager/index'),
				meta: {
					title: '医疗机构绩效管理',
					roles: ['crud'],
				},
			},
		],
	},
	{
		path: '/performanceManagerResult',
		component: Layout,
		redirect: '/performanceManagerResult/index',
		name: 'performanceManagerResult',
		meta: {
			title: '绩效体系',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/performanceManagerResult/index',
				name: 'index',
				component: () => import('@/views/performanceManagerResult/index'),
				meta: {
					title: '乡镇卫生院绩效评价',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/staticManagement',
		component: Layout,
		children: [
			{
				path: '/staticManagement',
				name: 'staticManagement',
				component: () => import('@/views/staticManagement/index'),
				meta: {
					title: '统计分析',
					roles: ['view', 'crud'],
				},
			},
		],
	},
	{
		path: '/account',
		component: Layout,
		redirect: '/account/personalInfo',
		name: 'account',
		meta: {
			title: '账号设置',
			roles: ['view', 'crud'],
		},
		children: [
			{
				path: '/account/personalInfo',
				name: 'personalInfo',
				component: () => import('@/views/account/personalInfo'),
				meta: {
					title: '个人信息',
					roles: ['view', 'crud'],
				},
			},
		],
	},
	{
		path: '*',
		redirect: '/404',
		hidden: true,
	},
];
const createRouter = () =>
	new VueRouter({
		// mode: 'history', // require service support
		scrollBehavior: () => ({ y: 0 }),
		routes: constantRoutes,
	});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}

export default router;
