import $http from '@/request/https';

const mapDataModule = {
	namespaced: true,
	state: {
		// 当前地址的省或者市或者区或者村的名字和code
		addressInfo: {},
		mapDataArr: [], //地图数据
		mapDataArrCom: [], //社区数据
		filterData: {},
		//巡诊驻派地图上方统计
		statisticsList: [
			{ label: '机构数量', num: 0 },
			{ label: '巡诊组个数', num: 0 },
			{ label: '驻派组个数', num: 0 },
			{ label: '参与医生人数', num: 0 },
			{ label: '车辆数量', num: 0 },
			{ label: '设备数量', num: 0 },
		],
		parentInfo: [
			{
				cityCode: 100000,
				name: '全国',
			},
		], // 地图点击下钻栈
		mapLevel: 0, //当前地图点击的次数，点击省市区一次加一
	},
	mutations: {
		// 设置地图层次
		getMapLevel(state, data) {
			state.mapLevel = data;
		},
		// 设置点击地图获取到的地址信息
		getAddressInfo(state, data) {
			state.addressInfo = data;
			state.filterData.regionCode = data.regionCode;
		},
		// 设置巡诊驻派地图上方统计数据
		getStatisticsData(state, val) {
			state.statisticsList[0].num = val.medicalOrganizations || 0;
			state.statisticsList[1].num = val.circuitDiagnosisGroups || 0;
			state.statisticsList[2].num = val.residencyGroups || 0;
			state.statisticsList[3].num = val.participateMedicalStaffs || 0;
			state.statisticsList[4].num = val.vehicles || 0;
			state.statisticsList[5].num = val.devices || 0;
		},
		// 设置地图选择地址，关键字搜索以及巡查类型筛选字段
		getMapFilter(state, data) {
			state.filterData = data;
		},
		// 设置地图数据
		getMapData(state, data) {
			state.mapDataArr = data;
		},
		//社区tab数据
		getMapDataCom(state, data) {
			state.mapDataArrCom = data;
		},
		getParentInfo(state, data) {
			state.parentInfo = data;
		},
	},
	actions: {
		// 获取地图baseData数据
		setBaseData({ commit, state }) {
			return new Promise((resolve, reject) => {
				$http.get('/management-api/v1/BaseData.json', { params: state.filterData }).then((res) => {
					if (res.data?.success) {
						if (state.addressInfo.iscommunity) {
							commit('getMapDataCom', res.data.collection[0].baseDataList || []);
						} else {
							commit('getMapData', res.data.collection[0].baseDataList || []);
							commit('getStatisticsData', res.data.collection[0].baseAllData || []);
							resolve(res.data);
						}
					} else {
						reject(res.data);
					}
				});
			});
		},
		// 设置地址信息
		async setAddressInfo({ commit }, data) {
			if (data) {
				commit('getAddressInfo', data);
			}
		},
	},
};

export default mapDataModule;
