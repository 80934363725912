import router from './router';
import store from './store';
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
const whiteList = ['/login']; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
	document.title = '加载中...';
	const hasToken = Session.get('token') || null;

	if (hasToken) {
		if (to.path === '/login') {
			next({ path: '/' });
		} else {
			const hasGetUserInfo = store.getters.name;
			if (hasGetUserInfo) {
				next();
			} else {
				try {
					next();
				} catch (error) {
					await store.dispatch('user/resetToken');
					Message.error(error || 'Has Error');
					next(`/login?redirect=${to.path}`);
				}
			}
		}
	} else {
		if (whiteList.indexOf(to.path) !== -1) {
			next();
		} else {
			next(`/login`);
		}
	}
});

router.afterEach(() => {
	// finish progress bar
});
