import dayjs from 'dayjs'
import Vue from 'vue'

const homePage = {
	namespaced: true,
	state: {
    scale:1, // 大屏 transform scale 适配
		timeRange: [dayjs().endOf('month').subtract(12, 'month').toDate(), dayjs().endOf('month').toDate()],
		monthPeriod:1, //选择时间跨度基数
    currentMonthPeriod: 1,
		heads: {
			name: '',
			index: 1
		},
		block: {
			healthRes: 0, // 卫生资源
			ruralHealth: 0, // 农村卫生
			medExpense: 0, // 病人医药费用
			medService: 0, // 医疗效劳
			commHealth: 0, // 社区卫生
			teamDor: 0, // 医生团队
			inmateDor: 0, // 居民健康管理
			crowdDor: 0, // 服务人群
			massDor: 0, // 服务质量
			puerperaHealth: 0, // 妇幼卫生
			basicData: 0, // 基础数据
			diseaseManage: 0, // 慢性病管理
			eduPublicity: 0, // 教育宣传
			serviceSys: 0, // 服务体系
			workIndicators: 0, // 工作指标
			businessTraining: 0, // 业务培训
		},
	},
	mutations: {
		getCacheKeepAlive(state, data) {
			state.keepAliveNames = data;
		},
    setTimeRange(state, data) {
      Vue.set(state.timeRange, 0, data[0])
      Vue.set(state.timeRange, 1, data[1])
    }
	},
	actions: {
		async setCacheKeepAlive({ commit }, data) {
			commit('getCacheKeepAlive', data);
		},
	},
};

export default homePage;
